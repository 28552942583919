.header-main {
    font-family: var(--crimson_text);
}

.header-main h1 {
    font-size: 4.5rem;
    margin: 0;
}

@media screen and (max-width: 770px) {
    .header-main h1 {
        font-size: 3.2rem;
    }
}

@media screen and (max-width: 500px) {
    .header-main h1 {
        font-size: 2.3rem;
    }
}

@media screen and (max-width: 375px) {
    .header-main h1 {
        font-size: 2.1rem;
    }
}

.header-main h5 {
    font-size: 2.5rem;
    margin: 0;
}

@media screen and (max-width: 770px) {
    .header-main h5 {
        font-size: 2.2rem;
    }
}

@media screen and (max-width: 500px) {
    .header-main h5 {
        font-size: 1.8rem;
    }
}

@media screen and (max-width: 375px) {
    .header-main h5 {
        font-size: 1.6rem;
    }
}

.contact-text {
    font-family: var(--montserrat);
    font-size: 1.25rem;
}

.main-text {
    font-family: var(--montserrat);
    font-size: 1rem;
    line-height: 1.3rem;
}

.main-text-skills {
    font-family: var(--montserrat);
    font-size: 1.2rem;
    line-height: 1.5rem;
    text-align: center;
}

.project-skills {
    text-align: center;
}

.project-title {
    font-size: 1.8rem;
    font-family: var(--crimson_text);
    margin: 1.5rem 0;
}

.project-text {
    margin: 0.75em 0;
}

