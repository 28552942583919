.project-image {
    width: 55%;
    align-self: center;
}

@media screen and (max-width: 699px) {
    .project-image {
        width: 65%;
    }
}

.project-article {
    display: flex;
    flex-direction: column;
    margin-top: 1rem;
}

.project-title {
    margin: 0 0 1.5rem;
}

.project-links {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.fa-2x {
    padding: 1rem;
}

.project-article a {
    color: black;
}