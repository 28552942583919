@import url('https://fonts.googleapis.com/css2?family=Crimson+Text&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');

:root {
    --crimson_text: 'Crimson Text', serif;
    --montserrat: 'Montserrat', sans-serif;
}


body {
    background-color: rgb(193,225,225);
}

* {
    box-sizing: border-box;
}