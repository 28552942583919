.contact-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.email-icon {
    padding-bottom: 1.5rem;
}

.social-links i {
    padding: 1.5rem;
}

.contact-wrapper a {
    color: black;
}
