@import url(https://fonts.googleapis.com/css2?family=Crimson+Text&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
    --crimson_text: 'Crimson Text', serif;
    --montserrat: 'Montserrat', sans-serif;
}


body {
    background-color: rgb(193,225,225);
}

* {
    box-sizing: border-box;
}
.container {
    width: 66vw;
    margin: 3rem 18vw 2rem 16vw;
}

@media screen and (min-width: 1500px) {
    .container {
        width: 45vw;
        margin: 3rem 30vw 2rem 26vw;
    }
}

@media screen and (max-width: 770px) {
    .container {
        width: 72vw;
        margin: 2rem auto;
    }
}

@media screen and (max-width: 500px) {
    .container {
        width: 88vw;
        margin: 2rem auto;
    }
}

.page-content {
    position: relative;
}

.main-image {
    position: relative;
    overflow: hidden;
    width: 33%;
    float: left;
    margin-right: 1rem;
    margin-bottom: 0.75rem;
    border-radius: 5px;
}

@media screen and (max-width: 1260px) {
    .main-image {
        margin-top: 0.25rem;
    }
}


.header-main {
    font-family: var(--crimson_text);
}

.header-main h1 {
    font-size: 4.5rem;
    margin: 0;
}

@media screen and (max-width: 770px) {
    .header-main h1 {
        font-size: 3.2rem;
    }
}

@media screen and (max-width: 500px) {
    .header-main h1 {
        font-size: 2.3rem;
    }
}

@media screen and (max-width: 375px) {
    .header-main h1 {
        font-size: 2.1rem;
    }
}

.header-main h5 {
    font-size: 2.5rem;
    margin: 0;
}

@media screen and (max-width: 770px) {
    .header-main h5 {
        font-size: 2.2rem;
    }
}

@media screen and (max-width: 500px) {
    .header-main h5 {
        font-size: 1.8rem;
    }
}

@media screen and (max-width: 375px) {
    .header-main h5 {
        font-size: 1.6rem;
    }
}

.contact-text {
    font-family: var(--montserrat);
    font-size: 1.25rem;
}

.main-text {
    font-family: var(--montserrat);
    font-size: 1rem;
    line-height: 1.3rem;
}

.main-text-skills {
    font-family: var(--montserrat);
    font-size: 1.2rem;
    line-height: 1.5rem;
    text-align: center;
}

.project-skills {
    text-align: center;
}

.project-title {
    font-size: 1.8rem;
    font-family: var(--crimson_text);
    margin: 1.5rem 0;
}

.project-text {
    margin: 0.75em 0;
}


.menu-dropdown {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.menu-dropdown a {
    text-decoration: none;
    color: black;
    width: 11vw;
}

@media screen and (min-width: 1500px) {
    .menu-dropdown a{
        width: 9vw;
    }
}

.menu-heading {
    padding-top: 0.5rem;
    font-family: var(--crimson_text);
    font-size: 2rem;
    margin: 1rem 0;
}

.no-highlight {
    border-top: 1.5px solid black;
    padding-top: 10.5px;
}

.highlight, .menu-heading:hover{
    border-top: 4px solid black;
    padding-top: 8px;
}

.contact-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.email-icon {
    padding-bottom: 1.5rem;
}

.social-links i {
    padding: 1.5rem;
}

.contact-wrapper a {
    color: black;
}

.icon-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin-bottom: 2rem;
}

@media screen and (max-width: 699px) and (min-width: 400px) {
    .fa-5x {
        font-size: 4em;
    }
}

@media screen and (max-width: 399px) {
    .fa-5x {
        font-size: 3em;
    }
}
.project-image {
    width: 55%;
    align-self: center;
}

@media screen and (max-width: 699px) {
    .project-image {
        width: 65%;
    }
}

.project-article {
    display: flex;
    flex-direction: column;
    margin-top: 1rem;
}

.project-title {
    margin: 0 0 1.5rem;
}

.project-links {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.fa-2x {
    padding: 1rem;
}

.project-article a {
    color: black;
}

