.icon-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin-bottom: 2rem;
}

@media screen and (max-width: 699px) and (min-width: 400px) {
    .fa-5x {
        font-size: 4em;
    }
}

@media screen and (max-width: 399px) {
    .fa-5x {
        font-size: 3em;
    }
}