.menu-dropdown {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.menu-dropdown a {
    text-decoration: none;
    color: black;
    width: 11vw;
}

@media screen and (min-width: 1500px) {
    .menu-dropdown a{
        width: 9vw;
    }
}

.menu-heading {
    padding-top: 0.5rem;
    font-family: var(--crimson_text);
    font-size: 2rem;
    margin: 1rem 0;
}

.no-highlight {
    border-top: 1.5px solid black;
    padding-top: 10.5px;
}

.highlight, .menu-heading:hover{
    border-top: 4px solid black;
    padding-top: 8px;
}
