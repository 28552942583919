.container {
    width: 66vw;
    margin: 3rem 18vw 2rem 16vw;
}

@media screen and (min-width: 1500px) {
    .container {
        width: 45vw;
        margin: 3rem 30vw 2rem 26vw;
    }
}

@media screen and (max-width: 770px) {
    .container {
        width: 72vw;
        margin: 2rem auto;
    }
}

@media screen and (max-width: 500px) {
    .container {
        width: 88vw;
        margin: 2rem auto;
    }
}

.page-content {
    position: relative;
}

.main-image {
    position: relative;
    overflow: hidden;
    width: 33%;
    float: left;
    margin-right: 1rem;
    margin-bottom: 0.75rem;
    border-radius: 5px;
}

@media screen and (max-width: 1260px) {
    .main-image {
        margin-top: 0.25rem;
    }
}

